import { variables } from '@indriver/yrel';

import { getColorFromTheme } from '@/lib/format/css-variables';

export const iframeInputStyles = () => `
    .Yuno-fieldset__box,
    .Yuno-input__base,
    .Yuno-input__content {
      background: ${getColorFromTheme(variables['background-secondary'])} !important;
      color: ${getColorFromTheme(variables['text-and-icon-primary'])} !important;
      border-radius: none !important;
      border: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      height: initial !important;
      
      font-size: 16px !important;
      line-height: 22px !important;
      font-weight: 500 !important;
    }`;
