import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { getCountryCode, getCountryLanguage } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import { FormValues } from '@/lib/payments/forms';
import Yuno from '@/lib/payments/integrations/yuno';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';


const YunoCheckoutController: FC<PaymentFormData> = ({
    paymentInfo,
    services,
    store,
    onHideGoBack
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showFillForm, setShowFillForm] = useState(store.selectedMethod.formFields.length > 0);

    useEffect(() => {
        onHideGoBack();

        if (store.selectedMethod.formFields.length === 0) {
            handleSubmit({});
        }

    }, []);

    const handleError = useCallback((e: string) => {
        services.logsService.write(e);

        if (e === 'CANCELED_BY_USER') {
            services.navigatorService.goHome();

            return;
        }
        services.navigatorService.showFinalPage('error', {
            message: `yuno checkout: ${e}`
        });
    }, []);

    const handleSubmit = async (
        values: FormValues,
    ): Promise<GQLErrorType> => {
        return new Promise((resolve) => {
            setShowFillForm(false);
            setLoading(true);

            const sdk = new SDK(services.logsService);
            sdk.get(PaymentProviderName.Yuno).then((initialize => {
                if (!store.selectedMethod.session?.yuno?.apiKey) {
                    services.navigatorService.showFinalPage('error', {
                        message: getSessionErrorMessage(),
                    });

                    return;
                }

                const yunoInstance = initialize(store.selectedMethod.session.yuno.apiKey);
                services.topupService.setYunoInstance(yunoInstance);

                try {
                    yunoInstance.startCheckout({
                        checkoutSession: store.selectedMethod.session.yuno.sessionToken,
                        elementSelector: `#${Yuno.CHECKOUT_ELEMENT_ID}`,
                        countryCode: getCountryCode(paymentInfo.country),
                        language: Yuno.onlyYunoLanguages(getCountryLanguage(paymentInfo.country) || i18n.language),
                        showLoading: true,
                        card: {
                            // Battle of Jamaica Conversion
                            // https://indriver.slack.com/archives/C03TDLP7AQM/p1708959077412249
                            cardSaveEnable: paymentInfo.country !== Countries.Jamaica
                        },
                        yunoCreatePayment: async (oneTimeToken: string) => {
                            setLoading(true);
                            const result = await services.topupService.topUp(
                                store.amount,
                                store.selectedMethod,
                                {
                                    ...values,
                                    oneTimeToken,
                                },
                                paymentInfo.country,
                            );

                            resolve(result);
                            setLoading(false);
                        },
                        yunoError: handleError,
                    });
                } catch (e) {
                    Sentry.captureException(`yuno checkout error: ${e}`);
                    services.logsService.write(`yuno checkout error: ${e}`);
                }

                yunoInstance.mountCheckoutLite({
                    paymentMethodType: store.selectedMethod.session.yuno.methodType,
                    vaultedToken: store.selectedMethod.session.yuno.vaultedToken
                }).then(() => setLoading(false));
            }));
        });
    };

    return (
        <CenterLoader
            loading={loading}
            overlay
        >
            <div id={Yuno.CHECKOUT_ELEMENT_ID} />

            {

                showFillForm ? (
                    <FormFieldView
                        country={paymentInfo.country}
                        savedDocuments={paymentInfo.savedDocuments}
                        formFields={store.selectedMethod.formFields}
                        onSubmit={handleSubmit}
                        childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
                    />
                ) : null
            }
        </CenterLoader>
    );
};

export default YunoCheckoutController;
