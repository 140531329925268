import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PayrailsCard, {
    PayrailsCardData
} from '@/features/credit-card/payrails-card';
import FillForm from '@/features/payment-fill-form';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import FormInput from '@/shared/ui/core/form-input';
import SubmitForm from '@/shared/ui/domain/submit-form';

import { PayrailsInitOptions } from '../../../../../../graphql/payrails';
import Resources from '../../../../../../resources';
import { AppData } from '../../../../index';

type PayrailsSavedCardViewProps = {
    initOptions: PayrailsInitOptions;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    panValue: string;
    cvvOnly: boolean;
    formFields: FormSection[];
    logsService: LogsService;
    configurationService: ConfigurationService;
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
    onError: (error: string) => void;
};

const PayrailsSavedCardView: FC<PayrailsSavedCardViewProps> = (props) => {
    const creditCardFormRef = useRef<CreditCardForm<PayrailsCardData>>(null);

    const { country, onSubmit, savedDocuments, formFields, panValue } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const paymentFillFormRef = useRef<FillForm>(null);

    const handleSubmit = useCallback(async () => {
        let data: Record<string, string> = {};

        if (paymentFillFormRef.current) {
            if (!paymentFillFormRef.current.validate()) {
                return;
            }

            const paymentFillFormData = paymentFillFormRef.current.getData();

            data = {
                ...paymentFillFormData,
            };
        }

        if (creditCardFormRef.current) {
            const creditCardData = await creditCardFormRef.current.getData();
            if (creditCardData) {
                data = {
                    ...data,
                    ...creditCardData,
                };
            }
        }

        setLoading(true);

        const error = await onSubmit(data);
        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }

            setLoading(false);
        }
    }, [paymentFillFormRef]);

    return (
        <SubmitForm
            loading={loading}
            onSubmit={handleSubmit}
        >
            <FormInput
                disabled
                title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                data-id={Resources.test.existedCard} // todo hide into creditcard outline?
                // https://indriver.atlassian.net/browse/PRC-2269
                value={panValue}
            />

            {
                props.cvvOnly ? (
                    <PayrailsCard
                        ref={creditCardFormRef}
                        initOptions={props.initOptions}
                        cvvOnly={props.cvvOnly}
                        logsService={props.logsService}
                        configurationService={props.configurationService}
                        onError={props.onError}
                    />
                ) : <></>
            }

            <FillForm
                ref={paymentFillFormRef}
                country={country}
                savedDocuments={savedDocuments}
                fields={formFields}
            />
        </SubmitForm>
    );
};
export default PayrailsSavedCardView;
