import { variables } from '@indriver/yrel';

export const getColorFromTheme = (variable: typeof variables[keyof typeof variables]) => {
    const match = variable.match(/\(--([^)]+)\)/);

    const element = document.querySelector('main');
    if (!element) {
        return '';
    }

    return  getComputedStyle(element).getPropertyValue(match ? '--' + match[1] : '').trim();
};
