import { FC, useRef, } from 'react';
import { useTranslation } from 'react-i18next';

import InDriverCard, {
    InDriverCardData
} from '@/features/credit-card/in-driver-card';
import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import InDriverSecureService from '@/services/indrive-secure-service';

import { AppData } from '../../../index';

type InDriverBankCardViewProps = {
    indriveSecureService: InDriverSecureService;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (values: FormValues<InDriverCardData>) => Promise<GQLErrorType>;
}

const InDriverBankCardView: FC<InDriverBankCardViewProps> = ({
    indriveSecureService,
    country,
    savedDocuments,
    formFields,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const creditCardFormRef = useRef<CreditCardForm<InDriverCardData>>(null);

    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        if (!creditCardFormRef.current?.validate()) {
            return;
        }

        const creditCardData = await creditCardFormRef.current?.getData();

        if (creditCardData === null) {
            return;
        }

        return onSubmit({
            ...creditCardData,
            ...values
        });
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <InDriverCard
                ref={creditCardFormRef}
                indriveSecureService={indriveSecureService}
            />
        </FormFieldView>
    );
};

export default InDriverBankCardView;
