import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { FormValues } from '@/lib/payments/forms';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const DlocalWalletController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['TopupWithDlocalWalletInput']>(input: T) => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Dlocal,
            payment_option: PaymentType.WALLET,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topUpWithDlocalWallet(input);

            if (data?.topupWithDlocalWallet?.redirectUrl === '') {
                services.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.topupWithDlocalWallet?.redirectUrl) {
                services.navigatorService.goOutside(data.topupWithDlocalWallet.redirectUrl);
            }
        });
    };

    const handleSubmitPayment = async (values: FormValues): Promise<GQLErrorType> => {
        if (!store.selectedMethod.paymentMethodCode) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage(),
            });

            return;
        }

        return handlePayment({
            ...values,
            username: values.username,
            email: values.email,
            document: values.document,
            amount: parseInt(store.amount, 10),
            paymentMethodCode: store.selectedMethod.paymentMethodCode,
        });
    };

    return (
        <FormFieldView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            title={t('views.PaymentMethods.getVoucherSubmitCaption')}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmitPayment}
        />
    );
};

export default DlocalWalletController;
