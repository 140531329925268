import * as Sentry from '@sentry/react';

import { DEFAULT_LANG } from '@/lib/locale';

class Yuno {
    static SUPPORTING_LANGUAGES = [
        'es',
        'en',
        'pt',
        'id',
        'th'
    ];

    static CHECKOUT_ELEMENT_ID = 'yuno-checkout';

    static onlyYunoLanguages = (code: string): typeof Yuno.SUPPORTING_LANGUAGES[number] => {
        if (!code) {
            Sentry.captureEvent({
                message: 'Language for yuno is undefied',
                level: 'info',
                extra: {
                    code,
                }
            });

            return DEFAULT_LANG;
        }

        if (Yuno.SUPPORTING_LANGUAGES.includes(code)) {
            return code;
        }

        return DEFAULT_LANG;
    };
}

export default Yuno;
