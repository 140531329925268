import { FC, useEffect, useState } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { COMMON_LOCALE_INFO } from '@/lib/locale';
import { FormValues } from '@/lib/payments/forms';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import NuveiService from '@/services/nuvei-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import NuveiNewBankCardView from './new-card';
import NuveiSavedBankCardView from './saved-card';

import { AuthorizeWithNuveiInput } from '../../../../../../graphql/bank-card/nuvei/authorize-with-nuvei';
import { TopupWithNuveiResponse } from '../../../../../../graphql/bank-card/nuvei/top-up-with-nuvei';
import Resources from '../../../../../../resources';

const NuveiBankCardController: FC<PaymentFormData> = ({ services, store, paymentInfo }) => {
    const [nuveiService, setNuveiService] = useState<NuveiService | undefined>();

    const initService = async () => {
        const sdk = new SDK(services.logsService);
        let nuveiData: TopupWithNuveiResponse | null | undefined;

        await services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithNuvei({ amount: parseInt(store.amount, 10) });
            nuveiData = data;
        });

        if (!nuveiData) {
            return;
        }

        const SafeCharge = await sdk.get(PaymentProviderName.Nuvei);
        const safeCharge = new SafeCharge({
            env: services.configurationService.isProd() ? 'prod' : 'int',
            merchantId: Resources.NUVEI_SDK.MERCHANT_ID,
            merchantSiteId: Resources.NUVEI_SDK.MERCHANT_SITE_ID,
            sessionToken: nuveiData.topupWithNuvei.providerSession
        });
        setNuveiService(new NuveiService({
            configurationService: services.configurationService,
            safeCharge,
            paymentID: nuveiData.topupWithNuvei.paymentID,
            sessionToken: nuveiData.topupWithNuvei.providerSession,
            country: COMMON_LOCALE_INFO[paymentInfo.country][0]
        }));
    };

    const formFields = store.selectedMethod.formFields;
    const isInstrument = store.selectedMethod.isInstrument;

    useEffect(() => {
        if (!isInstrument) {
            initService();
        }
    }, []);

    const handleSubmitCard = async (
        values: FormValues<AuthorizeWithNuveiInput>
    ): Promise<GQLErrorType> => {
        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.authorizeWithNuvei(values);
            if (data) {
                await services.navigatorService.showFinalPage('success');

                return;
            }
        });
    };

    const handleSubmitSavedCard = async (values: FormValues): Promise<GQLErrorType> => {
        const paymentInstrumentID = store.selectedMethod.savedInstruments?.bankCard?.[0]?.id;
        if (!paymentInstrumentID) {
            return;
        }

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithNuveiSavedCard({
                email: values.email,
                amount: parseInt(store.amount, 10),
                paymentInstrumentID
            });
            if (data) {
                await services.navigatorService.showFinalPage('success');

                return;
            }
        });
    };

    if (isInstrument) {
        return (
            <NuveiSavedBankCardView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={formFields}
                onSubmit={handleSubmitSavedCard}
            />
        );
    }

    if (!nuveiService) {
        return <CenterLoader loading />;
    }

    return (
        <NuveiNewBankCardView
            nuveiService={nuveiService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmitCard}
        />
    );
};

export default NuveiBankCardController;
