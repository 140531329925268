import * as Sentry from '@sentry/react';
import { FC, useCallback, useEffect, useState } from 'react';

import { InDriverCardData } from '@/features/credit-card/in-driver-card';
import { Countries } from '@/lib/locale/countries';
import { FormValues } from '@/lib/payments/forms';
import { getAmountSection } from '@/lib/payments/forms/sections';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import InDriverSecureService from '@/services/indrive-secure-service';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import PaymentProviderService from '@/services/payment-provider-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import InDriverBankCardView from './indriver-bank-card';
import * as UI from './ui';

type PCIDSSBankCardPageProps = {
    navigatorService: NavigatorService;
    ipcService: IPCService;
    logsService: LogsService;
    configurationService: ConfigurationService;
}

const PCIDSSBankCardPage: FC<PCIDSSBankCardPageProps> = ({
    navigatorService,
    ipcService,
    logsService,
    configurationService,
}) => {
    const [indriveSecureService, setIndriveSecureService] = useState<InDriverSecureService|undefined>();
    useEffect(() => {
        const sdk = new SDK(logsService);
        sdk.get(PaymentProviderName.InDriver)
            .then((collectSdk) => {
                const inDriverSecure = new InDriverSecureService(new collectSdk({
                    env: configurationService.isProd() ? 'prod' : 'dev',
                }));
                setIndriveSecureService(inDriverSecure);
            })
            .catch(error => {
                Sentry.captureException(error);

                navigatorService.showFinalPage('error', {
                    message: error
                });
            });
    }, []);

    const [paymentProviderService] = useState(new PaymentProviderService(
        navigatorService,
        new AuthorizationService(ipcService, logsService),
        logsService,
        ipcService,
        configurationService,
    ));

    const handleSubmitPayment = useCallback((values: FormValues<InDriverCardData>) => {
        return paymentProviderService.process(async (req) => {
            req.createPayment({
                card_token: values.cardToken,
                amount: parseInt(values.amount, 10),
            });

            navigatorService.showFinalPage('success');
        });
    }, []);

    if (!indriveSecureService) {
        return <CenterLoader loading />;
    }

    return (
        <UI.Wrapper>
            <InDriverBankCardView
                indriveSecureService={indriveSecureService}
                country={Countries.Brazil}
                savedDocuments={
                    {
                        email: '',
                        document: '',
                        phone: '',
                        address: '',
                        billingAddress: '',
                    }
                }
                formFields={[getAmountSection()]}
                onSubmit={handleSubmitPayment}
            />
        </UI.Wrapper>
    );
};

export default PCIDSSBankCardPage;
