import { getSessionErrorMessage } from '@/lib/errors/session';
import { Countries } from '@/lib/locale/countries';
import { FormValues } from '@/lib/payments/forms';
import { DOCUMENT_ID } from '@/lib/payments/forms/indentificators';
import Credorax from '@/lib/payments/integrations/credorax';
import { YunoInstance } from '@/lib/payments/integrations/yuno/types';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import AnalyticsService from '@/services/analytics-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentMethod } from '@/services/payment-info-service';
import PaymentProviderService, { DataType } from '@/services/payment-provider-service';

// todo https://indriver.atlassian.net/browse/PRC-1375
class TopupService {
    private analyticService: AnalyticsService;
    private navigatorService: NavigatorService;
    private process: PaymentProviderService['process'];
    private yunoInstance?: YunoInstance;

    constructor(
        paymentProviderService: PaymentProviderService,
        analyticService: AnalyticsService,
        navigatorService: NavigatorService,
    ) {
        this.analyticService = analyticService;
        this.navigatorService = navigatorService;
        this.process = paymentProviderService.process.bind(paymentProviderService);
    }

    public async topUp(
        amount: string,
        method: PaymentMethod,
        values: FormValues,
        country: Countries,
    ) {
        this.analyticService.event('processing.topup_pay.click', {
            payment_option: method.paymentType,
            provider: method.providerName,
            sum: parseInt(amount, 10),
        });

        // This is a smooth migration for formless payments
        // Now it works only for Payrails
        // https://indriver.atlassian.net/browse/PRC-2181
        if (method.providerName === PaymentProviderName.Payrails) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.topUpWithPayrailsCard(amount, method, values);
            }

            return this.topupPayrailsGenericMethod(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Credorax) {
            return this.topUpWithCredorax(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Dlocal) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.createCardDlocal(amount, method, values, country);
            }
        }

        if (method.providerName === PaymentProviderName.Yuno) {
            return this.topUpWithYuno(amount, method, values);
        }

        return undefined;
    }

    private topUpWithPayrailsCard = async (amount: string, method: PaymentMethod, values: FormValues) => {
        const error = await this.process(async (req) => {
            if (!method.session?.payrails) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            if (method.isInstrument) {
                const { data } = await req.topUpWithSavedPayrailsCard({
                    session: {
                        sessionId: method.session.payrails.session,
                        transactionId: method.session.payrails.paymentId,
                        amount,
                    },
                    instrumentID: method.session.payrails.instrumentId,
                    amount: parseInt(amount, 10),
                    document: values[DOCUMENT_ID],
                    securityCodeToken: values.encryptedData,
                });

                if (data?.topupWithSavedCardPayrails.redirectUrl) {
                    this.navigatorService.goOutside(
                        data.topupWithSavedCardPayrails.redirectUrl,
                    );

                    return;
                }

                this.navigatorService.showFinalPage('success');
            }

            const payrailsData = values?.numberToken ? {
                numberToken: values.numberToken,
                securityCodeToken: values.securityCodeToken,
                vaultedToken: values.vaultedToken,
            } : {
                collectData: {
                    vaultProviderConfigId: values.vaultProviderConfigId,
                    encryptedData: values.encryptedData,
                },
            };

            const { data } = await req.topUpWithPayrailsCard({
                session: {
                    sessionId: method.session.payrails.session,
                    transactionId: method.session.payrails.paymentId,
                    amount,
                },
                ...payrailsData,
                document: values[DOCUMENT_ID],
            });

            if (data?.topupWithCardPayrails.redirectUrl) {
                this.navigatorService.goOutside(
                    data.topupWithCardPayrails.redirectUrl,
                );

                return;
            }

            this.navigatorService.showFinalPage('success');
        });

        if (error) {
            this.navigatorService.showFinalPage('error', {
                message: error.getOnlyOneError(),
            });
        }

        return error;
    };

    private topupPayrailsGenericMethod = async (amount: string, method: PaymentMethod, values: FormValues) => {
        const error = await this.process(async (req) => {
            if (!method.session?.payrails || !method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topupPayrailsGenericMethod({
                session: {
                    sessionId: method.session.payrails.session,
                    transactionId: method.session.payrails.paymentId,
                    amount: amount,
                },
                paymentMethodType: method.paymentType,
                paymentMethodCode: method.paymentMethodCode,
                // todo https://indriver.atlassian.net/browse/PRC-1375
                document: values.document || '',
            });

            if (data?.topupPayrailsGenericMethod.redirectUrl) {
                this.navigatorService.goOutside(data.topupPayrailsGenericMethod.redirectUrl);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });

        if (error) {
            this.navigatorService.showFinalPage('error', {
                message: error.getOnlyOneError(),
            });
        }

        return error;
    };

    private topUpWithCredorax = async (amount: string, method: PaymentMethod, values: FormValues) => {
        const session = method.session?.credorax;

        const input = method.savedInstruments?.bankCard ? {
            amount: parseInt(amount, 10),
            firstName: values.firstName,
            lastName: values.lastName,
            /**
             * Backend asking send below empty strings because it is required fields
             */
            pKeyResponse: '',
            email: '',
            zipCode: '',
            city: '',
            street: '',
            houseNumber: '',
        } : {
            amount: parseInt(amount, 10),
            pKeyResponse: JSON.stringify(
                await Credorax.getPKey(
                    {
                        cardNumber: values.cardNumber,
                        expirationMonth: values.expirationMonth,
                        expirationYear: values.expirationYear,
                        cvv: values.cvv,
                        cardHolder: values.cardHolder,
                    },
                    session?.merchantID ?? '',
                    session?.staticKey ?? ''
                ),
            ),
            email: values.email,
            zipCode: values.zipCode,
            city: values.city,
            street: values.street,
            houseNumber: values.houseNumber,
            firstName: values.cardHolder.split(' ')[0],
            lastName: values.cardHolder.split(' ')[1],
        };

        return this.process(async (req) => {
            await req.topUpWithCredorax(input);

            this.navigatorService.showFinalPage('success');
        });
    };

    private createCardDlocal = (amount: string, method: PaymentMethod, values: FormValues, country: Countries) => {
        let input: DataType['CreateCardDlocalInput'] = {
            token: values.token,
            amount: parseInt(amount, 10),
            cardHolderName: values.cardHolderName,
            email: values.email,
            document: values.document,
        };

        if (country === Countries.India || country === Countries.SouthAfrica) {
            input.state = values.state;
            input.city = values.city;
            input.zipCode = values.zipCode;
            input.street = values.street;
            input.houseNumber = values.houseNumber;
        }

        if (method.savedInstruments?.bankCard) {
            input = {
                amount: parseInt(amount, 10),
                document: values.document,
                email: values.email,
                token: country === Countries.India ? values.token : undefined,
            };
        }

        return this.process(async (req) => {
            const { data } = await req.createCardDlocal(input);

            if (data?.createCardDlocal.threeDSecureRedirectUrl === '') {
                await this.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.createCardDlocal.threeDSecureRedirectUrl) {
                this.navigatorService.goOutside(data?.createCardDlocal?.threeDSecureRedirectUrl);
            }
        });
    };

    public setYunoInstance = (instance: YunoInstance) => {
        this.yunoInstance = instance;
    };

    private topUpWithYuno = (
        amount: string,
        method: PaymentMethod,
        values: FormValues,
    ) => {
        return this.process(async (req) => {
            if (!method.session?.yuno) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topUpWithYuno({
                methodName: method.session.yuno.methodName,
                vaultedToken: method.session.yuno.vaultedToken,
                session: method.session.yuno.sessionToken,
                paymentMethodCode: method.paymentMethodCode || '',
                amount: parseInt(amount, 10),
                email: values.email,
                oneTimeToken: values.oneTimeToken,
            });

            if (!this.yunoInstance) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            if (data?.topupWithYuno.sdkActionIsRequired) {
                this.yunoInstance.continuePayment();

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };
}

export default TopupService;
