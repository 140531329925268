import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { AppData } from '../../../../index';

type NuveiSavedBankCardViewProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
}

const NuveiSavedBankCardView: FC<NuveiSavedBankCardViewProps> = ({
    country,
    savedDocuments,
    formFields,
    onSubmit
}) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const handleSubmit = async (values: FormValues) => {
        setLoading(true);

        return onSubmit(values);
    };

    return (
        <CenterLoader
            loading={loading}
        >
            <FormFieldView
                country={country}
                savedDocuments={savedDocuments}
                formFields={formFields}
                onSubmit={handleSubmit}
                childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
            />
        </CenterLoader>
    );
};

export default NuveiSavedBankCardView;
