import { FC, useCallback, useRef, useState } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import Checkbox from '@/shared/ui/core/checkbox';

import SubmitForm from '../../shared/ui/domain/submit-form';
import { AppData } from '../../singlepage/app';

import FillForm from '../payment-fill-form';

import * as UI from './ui';

type HidableFormFieldViewProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    hidableLabel?: string;
    hidableFields?: FormSection[];
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
};

const HidableFormFieldView: FC<HidableFormFieldViewProps> = ({
    country,
    savedDocuments,
    formFields,
    hidableLabel,
    hidableFields,
    onSubmit,
}) => {
    const [checked, setChecked] = useState(true);

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    const fillFormRef = useRef<FillForm>(null);
    const hidableFillFormRef = useRef<FillForm>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (loading) {
            // double click prevent
            return;
        }

        if (!fillFormRef.current?.validate()) {
            return;
        }

        if (hidableFillFormRef.current && !hidableFillFormRef.current.validate() ) {
            return;
        }

        const fillFormData = fillFormRef.current.getData();
        const hidableFillFormData = hidableFillFormRef.current?.getData();

        setLoading(true);

        const error = await onSubmit({
            ...fillFormData,
            ...hidableFillFormData
        });

        if (error) {
            if (fillFormRef.current) {
                fillFormRef.current.setError(error);
            }

            if (hidableFillFormRef.current) {
                hidableFillFormRef.current.setError(error);
            }
        }

        setLoading(false);
    }, [onSubmit]);

    return (
        <SubmitForm
            loading={loading}
            disabled={loading}
            onSubmit={handleSubmit}
        >
            <FillForm
                ref={fillFormRef}
                country={country}
                savedDocuments={savedDocuments}
                fields={formFields}
            />

            <>
                {
                    hidableLabel ? (
                        <Checkbox
                            title={hidableLabel}
                            onChange={handleCheckboxChange}
                            checked={checked}
                        />
                    ) : null
                }
            </>

            <UI.Wrapper>
                {
                    !checked && hidableFields ? (
                        <FillForm
                            ref={hidableFillFormRef}
                            country={country}
                            savedDocuments={savedDocuments}
                            fields={hidableFields}
                        />
                    ) : null
                }
            </UI.Wrapper>
        </SubmitForm>
    );
};

export default HidableFormFieldView;
