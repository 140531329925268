import { FC, useRef, } from 'react';
import { useTranslation } from 'react-i18next';

import CreditCardNative, { CardData } from '@/features/credit-card/credit-card-native';
import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';

import { AppData } from '../../../../index';

type CredoraxNewBankCardViewProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (values: FormValues<CardData>) => Promise<GQLErrorType>;
}

const CredoraxNewBankCardView: FC<CredoraxNewBankCardViewProps> = ({
    country,
    savedDocuments,
    formFields,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const creditCardFormRef = useRef<CreditCardForm<CardData>>(null);

    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        if (!creditCardFormRef.current?.validate()) {
            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();

        if (creditCardData === null) {
            return;
        }

        return onSubmit({
            ...creditCardData,
            ...values
        });
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <CreditCardNative ref={creditCardFormRef} />
        </FormFieldView>
    );
};

export default CredoraxNewBankCardView;
