import { useTheme } from '@indriver/nova';
import {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    FC,
    Ref,
} from 'react';
import { useTranslation } from 'react-i18next';

import YunoFields from '@/features/credit-card/yuno-card/fields';
import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import { YunoSecureFields } from '@/lib/payments/integrations/yuno/types';

import CardHolder from '../../../shared/ui/domain/credit-card-field/card-holder';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';
import CenterLoader from '../../../shared/ui/layout/center-loader';

export type YunoCardData = Record<string, string>;

type YunoCardProps = {
    ref: Ref<CreditCardForm<YunoCardData>>;
    secureFields: YunoSecureFields;
};

const YunoCard: FC<YunoCardProps> = forwardRef(({ secureFields }, ref) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const [currentTheme, setCurrentTheme] = useState(theme);
    const [fieldsState] = useState(new YunoFields(secureFields));
    const cardholderRef = useRef<CardHolder>(null);

    const [cardNumberError, setCardNumberError] = useState<ValidationError | undefined>();
    const [expirationError, setExpirationError] = useState<ValidationError | undefined>();
    const [cvvError, setCvvError] = useState<ValidationError | undefined>();
    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError | undefined>();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (currentTheme !== theme) {
            setCurrentTheme(theme);
            fieldsState.updateColors();
        }
    }, [currentTheme, theme]);

    useEffect(() => {
        fieldsState.addEventListener('allFieldsRendered', () => {
            setShow(true);
        });

        fieldsState.addEventListener('error', ({ field, error }) => {
            switch (field) {
                case YunoFields.ELEMENT_PAN:
                    setCardNumberError(error);
                    break;
                case YunoFields.ELEMENT_CVV:
                    setCvvError(error);
                    break;
                case YunoFields.ELEMENT_EXPIRATION:
                    setExpirationError(error);
                    break;
            }
        });

        fieldsState.createPan();
        fieldsState.createCvv();
        fieldsState.createExpiration();
    }, [fieldsState]);

    useImperativeHandle(ref, () => ({
        validate,
        getData,
        setCardholderError,
    }));

    const validate = (): boolean => {
        if (!fieldsState.validate(YunoFields.ELEMENT_PAN)) {
            return false;
        }

        if (!fieldsState.validate(YunoFields.ELEMENT_EXPIRATION)) {
            return false;
        }

        if (!fieldsState.validate(YunoFields.ELEMENT_CVV)) {
            return false;
        }

        if (!cardholderRef.current?.validate()) {
            return false;
        }

        return true;
    };

    const setCardholderError = (error?: ValidationError) => {
        if (error?.message) {
            setCardHolderError(error);
        }
    };

    const getData = async () => ({
        cardHolderName: cardHolder,
    });

    return (
        <CenterLoader
            title={t('features.CreditCard.loadingTitle')}
            overlay
            loading={!show}
        >
            <CreditCardOutline
                cardNumberInput={<div id={YunoFields.ELEMENT_PAN} />}
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children: <div id={YunoFields.ELEMENT_EXPIRATION} />,
                        expirationError: expirationError?.message,
                    }
                }
                cvvInput={<div id={YunoFields.ELEMENT_CVV} />}
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        onError={(error) => setCardHolderError(error)}
                        onChange={(value) => setCardHolder(value)}
                    />
                }
                cardHolderError={cardHolderError?.message}
            />
        </CenterLoader>
    );
});

export default YunoCard;
