import { FC, useCallback, useEffect, useState } from 'react';

import { Countries } from '@/lib/locale/countries';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import DlocalService from '@/services/dlocal-service';
import LogsService from '@/services/logs-service';
import NavigatorService, {
    CashlessRedirectPayload
} from '@/services/navigator-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import CashlessForm from './cashless-form';

import Resources from '../../../../resources';

type CashlessPageProps = {
    navigatorService: NavigatorService;
    logsService: LogsService;
}

const cashlessKeyRouting = (country: Countries) => {
    switch (country) {
        case Countries.Brazil:
            return Resources.DLOCAL_API_KEY.CASHLESS.BRAZIL;
        default:
            return Resources.DLOCAL_API_KEY.DEFAULT;
    }
};

const CashlessPage: FC<CashlessPageProps> = ({ navigatorService, logsService }) => {
    const [dlocalService, setDlocalService] = useState<DlocalService|undefined>();

    const handleTokenData = useCallback((payload: CashlessRedirectPayload) => {
        navigatorService.cashlessRedirect(payload);
    }, []);

    const handleError = useCallback((error: string) => {
        navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    useEffect(() => {
        const sdk = new SDK(logsService);
        sdk.get(PaymentProviderName.Dlocal)
            .then((sdkRef) => {
                // Because cashless team working in Brazil only
                const apiKey = cashlessKeyRouting(Countries.Brazil);

                const dlocal = new DlocalService(
                    Countries.Brazil,
                    sdkRef(apiKey),
                    logsService,
                );
                setDlocalService(dlocal);
            })
            .catch(error => {
                navigatorService.showFinalPage('error', {
                    message: error
                });
            });
    }, []);

    if (!dlocalService) {
        return <CenterLoader loading />;
    }

    return (
        <CashlessForm
            dlocalService={dlocalService}
            onTokenData={handleTokenData}
            onError={handleError}
        />
    );
};

export default CashlessPage;
