import { FC } from 'react';

import HidableFormFieldView from '@/features/hidable-form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import ErrorStatus from '@/lib/errors/status';
import { FormValues } from '@/lib/payments/forms';
import {
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID,
    BILLING_CITY_ID,
    CITY_ID,
    DOCUMENT_ID,
} from '@/lib/payments/forms/indentificators';

import { PaymentFormData } from '../index';

const IyzicoBankCardController: FC<PaymentFormData> = ({ store, services, paymentInfo }) => {
    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithIyzicoCard({
                amount: parseInt(store.amount, 10),
                document: values[DOCUMENT_ID],
                city: values[CITY_ID],
                address: values[FULL_ADDRESS_ID],
                billingCity: values[BILLING_CITY_ID] || values[CITY_ID],
                billingAddress: values[FULL_BILLING_ADDRESS_ID] || values[FULL_ADDRESS_ID],
            });

            if (data) {
                const redirect = data.topupWithIyzicoCard.redirectUrl;
                services.navigatorService.goOutside(redirect);

                return;
            }

            await services.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    return (
        <HidableFormFieldView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            hidableLabel={store.selectedMethod.hidableFormFields?.label}
            hidableFields={store.selectedMethod.hidableFormFields?.fields}
            onSubmit={handleSubmit}
        />
    );
};

export default IyzicoBankCardController;
