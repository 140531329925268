import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import { Header20Bold } from '@/shared/typography/text';

export const HeaderBold = styled.div`
  h2 {
    ${Header20Bold};
  }
`;

export const BodyWrapper = styled.div`
  margin: 0 4px;
`;

export const ItemWrapper = styled.div<{
    $selected: boolean;
    $show: boolean;
}>`
  padding-inline: 16px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 20px;

  max-height: 93px;
  opacity: 1;
  transition: .3s all ease-out;
  
  ${props => !props.$show && css`
    pointer-events: none;
    opacity: 0;
    max-height: 0px;
    border: none;
  `}

  ${props => props.$selected && css`
    border-color: ${variables['text-and-icon-primary']};
  `}

  &:last-child {
    margin-bottom: 28px;
  }
`;
